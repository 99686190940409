import React from 'react';
import { BrowserView, MobileView, isBrowser } from 'react-device-detect';
import { portfolio } from '../Component/staticData'
import aboutUs from "../Image/About Us HWS.png";

function Portfolio() {

    function Project() {
        return (
            portfolio.map((project) => {
                return isBrowser ? (
                <div className="flex row gap-24px">
                    <div className="flex col5">
                        <iframe src={project?.Img} alt="Project Image" height={'450px'} />
                    </div>
                    <div className="flex col5 py-60px">
                        <p>Project {project?.PortfolioId}</p>
                        <h2 className="HWS">{project?.Heading}</h2>
                        <ul className='aboutUL'>
                            {project?.Describtion.map((dataItem) => {
                                return (<li className='aboutLI' key={dataItem}>{dataItem.toString()}</li>);
                            })}
                        </ul>
                    </div>
                </div>
                ) : (<div className="flex col gap-16px">
                    <p>Project {project?.PortfolioId}</p>
                    <h2 className="heading">{project?.Heading}</h2>
                    <div className="flex">
                        <iframe src={project?.Img} alt="Project Image" height={'250px'} />
                    </div>
                    <div className="flex">
                        <ul className='aboutUL'>
                            {project?.Describtion.map((dataItem) => {
                                return (<li className='aboutLI' key={dataItem}>{dataItem.toString()}</li>);
                            })}
                        </ul>
                    </div>
                </div>)
            })
        )
    }

    return (
        <>
            <BrowserView>
                <section className="bg-section1 h-50 start-center">
                    <p className="tag mx-100px">Portfolio</p>
                    <h1 className="page-title">Our Work</h1>
                </section>
                <section className="mx-100px py-100px">
                    <Project />
                </section>
            </BrowserView>
            <MobileView>
                <section className="bg-section1 h-40 start-center">
                    <p className="tag">Portfolio</p>
                    <h1 className="page-title">Our Work</h1>
                </section>
                <section className="mx-20px py-30px">
                    <Project />
                </section>
            </MobileView>
        </>
    );
}

export default Portfolio;